<template>
  <v-app-bar id="app-bar" absolute app color="transparent" flat height="75">
    <v-btn
      v-if="user"
      class="mr-3"
      elevation="1"
      fab
      small
      @click="toggleDrawer(!drawer)"
    >
      <v-icon v-if="value"> mdi-view-quilt </v-icon>

      <v-icon v-else> mdi-dots-vertical </v-icon>
    </v-btn>

    <v-toolbar-title
      class="hidden-sm-and-down font-weight-light"
      v-text="$route.name"
    />

    <v-spacer />

    <div class="mx-3" />

    <v-btn v-if="!user" class="ml-2" min-width="0" text to="/pages/login">
      Login
    </v-btn>

    <v-btn
      v-if="!user"
      class="ml-2"
      min-width="0"
      text
      outlined
      color="primary"
      to="/pages/signup"
    >
      Signup
    </v-btn>

    <span v-if="user" class="ml-2" min-width="0">
      {{ user.email }}
    </span>

    <v-btn
      v-if="user"
      class="ml-2"
      min-width="0"
      text
      outlined
      color="primary"
      @click="logout"
    >
      Logout
    </v-btn>
  </v-app-bar>
</template>

<script>
// Utilities
import { mapState } from "vuex";

export default {
  name: "DashboardCoreAppBar",

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState({
      user: (state) => state.user.user,
      drawer: "drawer",
    }),
  },

  created() {
    // this.$store.dispatch("checkAuth");
  },

  methods: {
    toggleDrawer(value) {
      this.$store.dispatch("toggleDrawer", value);
    },
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.go({ path: "/" });
      });
    },
  },
};
</script>
